







































import Vue from "vue";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "InceptionSignUpHeader",
  components: {},
  data() {
    return {};
  },
  watch: {
    src_link() {
      this.play();
    }
  },
  async mounted() {
    this.play();
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  props: {
    src_link: {
      // video props
      type: String,
      default: "",
      required: true
    },
    text_question: {
      // text message props
      type: String,
      default: "",
      required: true
    }
  },
  methods: {
    // Play the video when the src_link changes
    play() {
      if (this.$refs?.videoElement) {
        const vid = this.$refs.videoElement as HTMLVideoElement;
        if (vid) {
          vid.load();
          vid.play();
        }
      }
    }
  }
});
